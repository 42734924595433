/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Products = '/products',
    Users = '/admin/users',
    OrderList = '/orderList',
    Faq = '/faq',
    Question = '/questions',
    PrivacyPolicy = '/privacy-policy',
    TermsAndCondition = '/terms-condition',
    AuthResetPassword = '/auth/reset-password',
    AuthSignUp = '/auth/signup',
    ForgotPasswordEmailSend = '/admin/email/send',
    ResetForgotPassword = '/admin/reset-password',
    VerifyAdminEmail = '/admin/verify-email',
    UpdatePassword = '/admin/update-password',
    Advertisement = '/Ads',
}
const entityArray = [
    { key: 'item-1', entityName: 'Users', entityUrl: AppRoutes.Users },
    { key: 'item-2', entityName: 'FAQs', entityUrl: AppRoutes.Faq },
    { key: 'item-3', entityName: 'Getting know Questions', entityUrl: AppRoutes.Question },
    { key: 'item-4', entityName: 'Advertisement', entityUrl: AppRoutes.Advertisement },
];

export { entityArray };
