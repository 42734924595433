import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PASSWORD, REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

const userListFormData = [
    {
        title: 'User Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'phone',
        label: 'Phone Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone Number' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'email',
        },
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [
            {
                pattern: REGEX_PASSWORD,
                message:
                    'Password must contain uppercase character, lowercase character, symbol, and number and of length 8',
            },
        ],
        errorMsg: errorMsg,
        tableMeta: { title: 'Update Password', columnType: 'confirmPassword' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { userListFormData };
